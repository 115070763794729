<template>
    <div>
        <div class="p-4">
            <b-card>
                <b-card-title style="margin-bottom: 45px">
                    Users
                    </b-card-title>
                <vue-table :pageSizes="[10, 20, 50, 100]" ref="table" :url="apiBase" :fields="fields" :perPage="10">
                </vue-table>
            </b-card>
        </div>
    </div>
</template>

<script>
// import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'ListUsers',
    data () {
        return {
            apiBase: urls.admin.users.list,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name',
                    titleClass: ''
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'E-Mail',
                    titleClass: ''
                },
                {
                    name: 'phone',
                    sortField: 'phone',
                    title: 'Phone',
                    titleClass: ''
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
